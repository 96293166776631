
import { defineComponent, ref } from "vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

import { useI18n } from "vue-i18n";
import SendActivationCode from "@/views/crafted/authentication/basic-flow/SendActivationCode.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Code from "@/components/Phone/Code.vue";
import userInfo from "@/core/services/UserInfo";

export default defineComponent({
  name: "change-password-profile",
  components: {
    Field,
    Form,
    ErrorMessage,
    SendActivationCode,
    Code,
  },
  props: {
    authData: Object,
  },
  setup() {
    const step = ref(1);
    const errorsBag = ref({});
    const store = useStore();
    const route = useRoute();
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    const user = userInfo.getUser();
    const mobile = ref(user.mobile);
    const code = ref();
    const submitButtonRegister = ref<HTMLButtonElement | null>(null);
    const sendCodeSubmit = ref<HTMLButtonElement | null>(null);
    const register = Yup.object().shape({
      password: Yup.string()
        .matches(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
          translate(
            "The password must contain at least one uppercase letter one lowercase letter one digit and have a minimum length of 8 characters"
          )
        )
        .min(8)
        .required()
        .label("confirm"),
      password_confirmation: Yup.string()
        .required()
        .oneOf([Yup.ref("password"), null], translate("Passwords must match"))
        .label("Password Confirmation"),
      code: Yup.string().required().label("code"),
    });

    const onSubmitChangePassword = (values) => {
      errorsBag.value = {};
      store.dispatch(Actions.LOGOUT);

      if (submitButtonRegister.value) {
        // eslint-disable-next-line
        submitButtonRegister.value!.disabled = true;
        // Activate indicator
        submitButtonRegister.value.setAttribute("data-kt-indicator", "on");
      }

      ApiService.setHeader();
      ApiService.post("auth/password-reminder", {
        mobile: mobile.value,
        code: code.value,
        password: values.password,
        password_confirmation: values.password_confirmation,
      })
        .then(() => {
          step.value = 1;
          Swal.fire({
            text: translate("done"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: translate("ok"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
        })
        .finally(() => {
          //Deactivate indicator
          submitButtonRegister.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
            submitButtonRegister.value!.disabled = false;
        });
    };

    const send = () => {
      if (sendCodeSubmit.value) {
        // eslint-disable-next-line
        sendCodeSubmit.value!.disabled = true;
        // Activate indicator
        sendCodeSubmit.value.setAttribute("data-kt-indicator", "on");
      }

      // send code
      let form = new FormData();
      form.append("mobile", mobile.value);
      ApiService.post("auth/send-code", form)
        .then(() => {
          step.value = 2;
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
        })
        .finally(() => {
          //Deactivate indicator
          sendCodeSubmit.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
        sendCodeSubmit.value!.disabled = false;
        });
    };

    const getCodes = (newCode) => {
      code.value = newCode;
    };

    const phone_data = ref();
    const handlePhone = (phoneObject) => {
      mobile.value = phoneObject.number;
      phone_data.value = phoneObject;
    };

    return {
      onSubmitChangePassword,
      register,
      submitButtonRegister,
      errorsBag,
      route,
      translate,
      code,
      getCodes,
      send,
      mobile,
      phone_data,
      step,
      handlePhone,
      sendCodeSubmit,
    };
  },
});
