import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "svg-icon svg-icon-2"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_tooltip, {
      class: "box-item",
      content: _ctx.translate('Receive verification code'),
      placement: "top-start"
    }, {
      default: _withCtx(() => [
        _withDirectives((_openBlock(), _createElementBlock("button", {
          type: "button",
          disabled: _ctx.counter !== 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args))),
          class: "btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
        }, [
          (_ctx.counter === 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr029.svg" })
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.counter), 1))
        ], 8, _hoisted_1)), [
          [_directive_loading, _ctx.loading]
        ])
      ]),
      _: 1
    }, 8, ["content"])
  ]))
}