
import { defineComponent, ref } from "vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import userInfo from "@/core/services/UserInfo";

export default defineComponent({
  name: "change-password-profile",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  props: {
    authData: Object,
  },
  setup() {
    const errorsBag = ref({});
    const store = useStore();
    const route = useRoute();
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    const user = userInfo.getUser();
    const mobile = ref(user.mobile);
    const code = ref();
    const submitButtonRegister = ref<HTMLButtonElement | null>(null);
    const sendCodeSubmit = ref<HTMLButtonElement | null>(null);
    const register = Yup.object().shape({
      current_password: Yup.string().required(translate("This field is required")).label("current_password"),
      new_password: Yup.string()
        .matches(
          /(?=.*?[0-9]).{8,}$/,
          translate(
            "رمز عبور حداقل باید شامل 8 کاراکتر باشد"
          )
        )
        .required(translate("This field is required"))
        .label("confirm"),
      new_password_confirmation: Yup.string()
        .required(translate("This field is required"))
        .oneOf(
          [Yup.ref("new_password"), null],
          translate("Passwords must match")
        )
        .label("Password Confirmation"),
    });

    const onSubmitChangePassword = (values) => {
      errorsBag.value = {};
      store.dispatch(Actions.LOGOUT);

      if (submitButtonRegister.value) {
        // eslint-disable-next-line
        submitButtonRegister.value!.disabled = true;
        // Activate indicator
        submitButtonRegister.value.setAttribute("data-kt-indicator", "on");
      }

      ApiService.post("user/change-password", {
        current_password: values.current_password,
        new_password: values.new_password,
        new_password_confirmation: values.new_password_confirmation,
      })
        .then(() => {
          Swal.fire({
            text: translate("done"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: translate("ok"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
        })
        .finally(() => {
          //Deactivate indicator
          submitButtonRegister.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
            submitButtonRegister.value!.disabled = false;
        });
    };

    return {
      onSubmitChangePassword,
      register,
      submitButtonRegister,
      errorsBag,
      route,
      translate,
      code,
      mobile,
      sendCodeSubmit,
    };
  },
});
