
import { defineComponent, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  name: "send-activation-code",
  props: {
    source: {
      type: String,
      default: "",
    },
  },
  emit: ["resetCodes"],

  setup(props: any, { emit }) {
    const loading = ref(false);
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const counter = ref(0);
    const myInterval = ref();
    const decrease = () => {
      --counter.value;
    };

    function myStopFunction() {
      clearInterval(myInterval.value);
    }
    const send = () => {
      // send code
      let form = new FormData();
      form.append("mobile", props?.source);
      loading.value = true;
      ApiService.post("auth/send-code", form)
        .then(() => {
          counter.value = 60;
          myInterval.value = setInterval(decrease, 1000);
            emit("resetCodes", true);

        })
        .catch(() => {
          Swal.fire({
            text: translate(
              "Unfortunately there was an error sending the verification code"
            ),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: translate("Try again"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        })
        .finally(() => {
          loading.value = false;
        });
    };

    watch(counter, () => {
      if (counter.value === 0) {
        myStopFunction();
        counter.value = 0;
      }
    });

    return {
      translate,
      send,
      loading,
      counter,
    };
  },
});
