
import { defineComponent, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n/index";
import PhoneInput from "@/components/Phone/PhoneInput.vue";
import Code from "@/components/Phone/Code.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import SendActivationCode from "@/views/crafted/authentication/basic-flow/SendActivationCode.vue";
export default defineComponent({
  name: "change-phone-number-settings",
  components: {
    PhoneInput,
    Code,
    SendActivationCode,
  },
  setup() {
    const step = ref(1);
    const store = useStore();
    const submitButton2 = ref<HTMLElement | null>(null);

    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const changeNumberData = ref({
      mobile: "",
      code: "",
      phone_data: {},
    });
    const changeNumber = (data) => {
      changeNumberData.value = data;
    };

    const sendCode = () => {
      let form = new FormData();
      form.append("mobile", changeNumberData.value.mobile);
      ApiService.post("auth/send-code", form)
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: translate(
              "Unfortunately there was an error sending the verification code"
            ),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: translate("Try again"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };

    watch(step, () => {
      if (step.value === 2) {
        sendCode();
      }
    });

    const changePhoneNumber = () => {
      if (submitButton2.value) {
        submitButton2.value.setAttribute("data-kt-indicator", "on");

        ApiService.post(`user/change-username`, {
          mobile: changeNumberData.value.mobile,
          code: changeNumberData.value.code,
        })
          .then(() => {
            step.value = 1;
            Swal.fire({
              text: translate("done"),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: translate("ok"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
            submitButton2.value?.removeAttribute("data-kt-indicator");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch(({ response }) => {
            if (response && response.data)
              store.commit(Mutations.SET_ERROR, response.data);
            submitButton2.value?.removeAttribute("data-kt-indicator");
          });
      }
    };
    const handlePhone = (phoneObject) => {
      changeNumberData.value.mobile = phoneObject.number;
      changeNumberData.value.phone_data = phoneObject;
    };

    const getCodes = (newCode) => {
      changeNumberData.value.code = newCode;
    };
    return {
      submitButton2,
      changePhoneNumber,
      changeNumber,
      changeNumberData,
      translate,
      handlePhone,
      getCodes,
      step,
    };
  },
});
