
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import UserInfo from "@/core/services/UserInfo";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus/es";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { Mutations } from "@/store/enums/StoreEnums";
import ChangePhoneNumber from "@/views/pages/other/settings/components/ChangePhoneNumber.vue";
import RemindePassword from "@/views/pages/other/settings/components/RemindePassword.vue";
import ChangePassword from "@/views/pages/other/settings/components/ChangePassword.vue";
import PhoneInput from "@/components/Phone/PhoneInput.vue";

interface ProfileDetails {
  avatar: string;
  name: string;
  surname: string;
  email: string;
  contactPhone: string;
  contact_number: string;
  card_number: any;
  sheba: any;
  address: string;
  postal_code: string;
  business_category: string;
  // password: string;
  country_id: string;
  city: string;
  panel_type: string;
  company_company_name: string;
  company_national_id: string;
  company_economic_code: string;
  company_registration_number: string;
  company_address: string;
  company_postal_code: string;
  company_phone: string;
}

export default defineComponent({
  name: "account-settings",
  components: {
    ErrorMessage,
    Field,
    Form,
    ChangePhoneNumber,
    RemindePassword,
    ChangePassword,
    PhoneInput,
  },
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const avatar = ref();
    const store = useStore();
    const submitButton1 = ref<HTMLElement | null>(null);

    const user = UserInfo.getUser();
    const profileDetails = ref<ProfileDetails>({
      avatar: user?.picture,
      name: user?.first_name,
      surname: user?.last_name,
      email: user?.email,
      contactPhone: user?.mobile,
      contact_number: user?.contact_number ? user?.contact_number : "+",
      card_number: user?.card_number !== undefined ? user?.card_number : "",
      sheba: user?.sheba !== undefined ? user?.sheba : "IR",
      address: user?.address,
      postal_code: user?.postal_code,
      business_category: user?.business_category || "",

      // password: user?.password,
      country_id: user?.country.alpha2_code,
      city: user?.city,
      panel_type: user?.panel_type || "person",
      company_company_name: user?.company_info?.company_name || "",
      company_national_id: user?.company_info?.national_id || "",
      company_economic_code: user?.company_info?.economic_code || "",
      company_registration_number:
        user?.company_info?.registration_number || "",
      company_address: user?.company_info?.address || "",
      company_postal_code: user?.company_info?.postal_code || "",
      company_phone: user?.company_info?.phone || "",
    });

    const imgUrl = ref(profileDetails.value.avatar as any);

    const OnChangeAvatar = () => {
      let reader = new FileReader();
      if (avatar.value.files[0]) {
        reader.onload = (e) => {
          imgUrl.value = e.target?.result;
        };
        reader.readAsDataURL(avatar.value.files[0]);
      }
    };

    const saveChanges1 = () => {
      const formData = new FormData();
      formData.append("picture", avatar.value.files[0]);
      formData.append("first_name", profileDetails.value.name);
      formData.append("last_name", profileDetails.value.surname);

      if (profileDetails.value.email !== null) {
        formData.append("email", profileDetails.value.email);
      }

      if (profileDetails.value.card_number) {
        formData.append("card_number", profileDetails.value.card_number);
      }
      if (profileDetails.value.sheba) {
        formData.append("sheba", profileDetails.value.sheba);
      }
      if (profileDetails.value.email !== null) {
        formData.append("gender", user.gender);
      }
      formData.append("mobile", profileDetails.value.contactPhone);
      formData.append("contact_number", profileDetails.value.contact_number);
      formData.append("country_id", profileDetails.value.country_id);
      if (profileDetails.value.address !== null)
        formData.append("address", profileDetails.value.address);
      if (profileDetails.value.city !== null)
        formData.append("city", profileDetails.value.city);
      if (profileDetails.value.postal_code !== null)
        formData.append("postal_code", profileDetails.value.postal_code);

      if (profileDetails.value.business_category !== null)
        formData.append(
          "business_category",
          profileDetails.value.business_category
        );

      if (profileDetails.value.panel_type !== null)
        formData.append("panel_type", profileDetails.value.panel_type);

      if (profileDetails.value.panel_type === "company") {
        formData.append(
          "company_info[" + "company_name" + "]",
          profileDetails.value.company_company_name
        );

        formData.append(
          "company_info[" + "national_id" + "]",
          profileDetails.value.company_national_id
        );

        formData.append(
          "company_info[" + "economic_code" + "]",
          profileDetails.value.company_economic_code
        );

        formData.append(
          "company_info[" + "registration_number" + "]",
          profileDetails.value.company_registration_number
        );

        formData.append(
          "company_info[" + "address" + "]",
          profileDetails.value.company_address
        );

        formData.append(
          "company_info[" + "postal_code" + "]",
          profileDetails.value.company_postal_code
        );

        formData.append(
          "company_info[" + "phone" + "] ",
          profileDetails.value.company_phone
        );
      }

      // if (profileDetails.value.password !== null)
      //   formData.append("password", profileDetails.value.password);
      if (submitButton1.value) {
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        ApiService.post(`user/profile`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(() => {
            store.dispatch(Actions.VERIFY_AUTH);
            ElNotification({
              title: translate("success"),
              message: translate("Profile updated"),
              type: "success",
            });
            setTimeout(function () {
              window.location.reload();
            }, 2000);
          })
          .catch(({ response }) => {
            if (response && response.data)
              store.commit(Mutations.SET_ERROR, response.data);
          });

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");
        }, 2000);
      }
    };

    const removeImage = () => {
      profileDetails.value.avatar = "/media/avatars/blank.png";
    };

    const getPhoneCode = (code) => {
      profileDetails.value.country_id = code;
    };

    const toEnglishDigits = (nums) => {
      // convert persian digits [۰۱۲۳۴۵۶۷۸۹]
      var e = "۰".charCodeAt(0);
      nums = nums.replace(/[۰-۹]/g, function (t) {
        return t.charCodeAt(0) - e;
      });

      // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
      e = "٠".charCodeAt(0);
      nums = nums.replace(/[٠-٩]/g, function (t) {
        return t.charCodeAt(0) - e;
      });

      return nums;
      // recipients.value = nums;
    };

    const cats_model = ref(false);
    const new_active = ref();

    const activitys = ref([
      {
        lable:
          "آموزش و تدریس: شامل آموزشگاه‌ها، پیج‌های آموزشی، مدرسین خصوصی و آنلاین.",
        value: "education_teaching",
      },
      {
        lable:
          "تبلیغات و بازاریابی دیجیتال: آژانس‌های تبلیغاتی، شرکت‌های دیجیتال مارکتینگ، مشاوران کسب‌وکار و بازاریابی.",
        value: "digital_marketing",
      },
      {
        lable: "سفر و مهاجرت: آژانس‌های مسافرتی، خدمات گردشگری، مهاجرتی و ویزا",
        value: "travel_migration",
      },
      {
        lable:
          "سلامت و درمان: کلینیک‌های پزشکی، روانپزشکی، مشاوره، پرستاری و خدمات بهداشتی",
        value: "healthcare_services",
      },
      {
        lable:
          "فناوری و IT: شرکت‌های نرم‌افزاری، سخت‌افزاری، برنامه‌نویسی و طراحی وب",
        value: "technology_it",
      },
      {
        lable: "املاک و خودرو: خرید و فروش املاک، وسایل نقلیه و خودرو",
        value: "real_estate_auto",
      },
      {
        lable:
          "خدمات مالی و حقوقی: شرکت‌های بیمه، مشاوره‌های مالی، خدمات حقوقی و اداری، ثبتی",
        value: "finance_legal",
      },
      {
        lable:
          "خدمات فنی و تعمیراتی: شرکت‌های خدماتی، تعمیراتی، تأسیساتی و نظافتی",
        value: "technical_repairs",
      },
      {
        lable:
          "خرده‌فروشی و فروشگاه‌ها: فروشگاه‌های خرده‌فروشی کالاهای مصرفی، اداری، صنعتی و لوازم خانگی",
        value: "retail_stores",
      },
      {
        lable:
          "کسب‌وکارهای کوچک و خانگی: کسب‌وکارهای شخصی، اینستاگرامی، خانگی و محلی",
        value: "small_home_business",
      },
      {
        lable: "صنعت و تولید: کارخانجات تولیدی و صنعتی",
        value: "industry_manufacturing",
      },
      {
        lable: "زیبایی و بهداشت: سالن‌های زیبایی، خدمات آرایشی و بهداشتی",
        value: "beauty_wellness",
      },
      {
        lable: "غذا و نوشیدنی: کافه‌ها، رستوران‌ها، قنادی‌ها و خدمات پذیرایی",
        value: "food_beverage",
      },
      {
        lable: "خدمات خودرو: تعمیرات و خدمات وسایل نقلیه و قطعات یدکی",
        value: "auto_services",
      },
      {
        lable: "فرهنگ و هنر: کسب‌وکارهای هنری، صنایع دستی و فرهنگی",
        value: "culture_arts",
      },
      // { lable: "سایر", value: "" },
    ]);

    const add_activity = () => {
      activitys.value.push({
        lable: new_active.value,
        value: new_active.value,
      });
      // business_category.value = new_active.value;
      cats_model.value = false;
    };

    // const userType = ref();
    onMounted(() => {
      // let user: any = localStorage.getItem("previous_user_info") || {};
      // userType.value = JSON.parse(user).type;
      // console.log(userType.value);

      setCurrentPageBreadcrumbs(translate("profile"), [translate("edit")]);
    });

    return {
      submitButton1,
      saveChanges1,
      profileDetails,
      removeImage,
      avatar,
      OnChangeAvatar,
      imgUrl,
      translate,
      getPhoneCode,
      user,
      toEnglishDigits,
      // userType,

      activitys,
      cats_model,
      new_active,
      add_activity,
    };
  },
});
